import React from 'react';
import {
  ChakraProvider,
  Box,
  Heading,
  Image,
  theme,
} from '@chakra-ui/react';

function App() {
  return (
    <ChakraProvider theme={theme}>
    <Image src="logoandtaglineheader.gif" />
      <Box>
		<Heading as="h1" mb="20px">Contact</Heading>
		<Box>Teravation</Box>
		<Box>10529 Dacre Pl</Box>
		<Box>Lone Tree, Colorado 80124</Box>
		
		<Box mt="20px">Phone:	303-378-5247</Box>
		<Box>Email:	info@teravation.com</Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
